export default {
  data() {
    return {
      nameProfile: "",
      abbrNameProfile: "",
      texts: "",
      menuHeight: 0,
      menuHeightResp: 0,
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      fav: true,
      menu: false,
      message: false,
      hints: true,
      closeOnClick: false,
      activeCatalogGroup: false,
      downList: false,
    };
  },
  beforeMount() {
    this.texts = FILE.navbarTexts[this.selectLanguage];
    this.nameProfile = this.$store.state.sFullName;

    if (this.nameProfile.split(" ").length == 4) {
      this.abbrNameProfile =
        this.nameProfile.split(" ")[0].charAt(0) +
        this.nameProfile.split(" ")[2].charAt(0);
    } else if (this.nameProfile.split(" ").length == 3) {
      this.abbrNameProfile =
        this.nameProfile.split(" ")[0].charAt(0) +
        this.nameProfile.split(" ")[1].charAt(0);
    } else if (this.nameProfile.split(" ").length == 2) {
      this.abbrNameProfile =
        this.nameProfile.split(" ")[0].charAt(0) +
        this.nameProfile.split(" ")[1].charAt(0);
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$route.name == "Subcategories") {
      this.downList = true;
      this.activeCatalogGroup = true;
    }
    this.$nextTick(()=>{
      this.openMenu(this.bMenuSidebar);
    })
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getPermitsById(item) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/permissions/${this.$store.state.sAdministratorId}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {},
        }
      )
        .then((response) => {
          if (this.$store.state.permissions !== null) {
            this.$store.commit("setPermissions", response.data.results);
          }

          if (
            this.$route.name.toUpperCase() !==
            item.href.substr(7, item.href.length).toUpperCase()
          ) {
            if (this.$store.state.permissions !== null) {
              if (
                this.$store.state.permissions
                  .map((e) => e.sPermissionId)
                  .includes(item.permission)
              ) {
                this.$router.push(item.href);
              }
            } else {
              this.$router.push(item.href);
            }

            this.$store.commit("setMenuSidebar", {active:false});
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setBackgroundColor: function(routeNames) {
      let bln = false;
      for (let i = 0; i < routeNames.length; i++) {
        if (this.$route.name == routeNames[i]) {
          bln = true;
        }
      }
      return bln;
    },
    setPositionsList: function(routeNames) {
      let bln = false;
      for (let i = 0; i < routeNames.length; i++) {
        if (this.$route.name == routeNames[i]) {
          bln = true;
        }
      }
      return bln;
    },
    handleResize: function() {
      window.innerWidth > 800 ? (this.menu = false) : "";
      this.menuHeight = window.innerHeight - 17;
      this.menuHeightResp = window.innerHeight - 245;
    },
    killSession() {
      if (this.$store.state.sToken !== "") {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.delete(`${URI}/api/v1/${this.selectLanguage}/session`, config)
          .then((response) => {
            localStorage.clear();
            this.$store.commit("setToken", "");
            // this.$router.push("/");
            this.$router.go();
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
    changeLanguage(val) {
      this.$store.commit("toggle_language", val);
    },
    setMenuSidebar() {
      var active = !this.bMenuSidebar
      this.$store.commit("setMenuSidebar", {
        active: active,
      });
    },
    openMenu(sidebar) {
      var menu = document.getElementById("menuBtnActive");
      if (sidebar) {
        menu.classList.add("v-menu-icon-open");
      } else {
        menu.classList.remove("v-menu-icon-open");
      }
    },
  },
  computed: {
    filteredItems: function() {
      return this.texts.items.filter((routeOption) => {
        if (this.userPermissions === null) {
          return routeOption;
        } else {
          var exp = this.userPermissions;

          let arr = "";
          exp.forEach((e) => {
            if (e.sPermissionId === routeOption.id) {
              arr = e;
            }
          });
          return arr;
        }
      });
    },
    userPermissions() {
      return this.$store.state.permissions;
    },
    sFullName() {
      return this.$store.state.sFullName;
    },
    bMenuSidebar() {
      if (this.$route.name == "Subcategories") {
        this.activeCatalogGroup = true;
      } 
      else {
        this.activeCatalogGroup = false;
      }
      return this.$store.state.bMenuSidebar;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    bMenuSidebar: function() {
      this.openMenu(this.bMenuSidebar);
    },
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.navbarTexts[this.selectLanguage];
      }
    },
  },
};
